/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react'
import { useSelector } from 'react-redux'

export default function Container ({
  flexDirection,
  children,
  customCss,
  className
}) {
  const theme = useSelector(state => state.theme)
  return (
    <div
      className={className
        ? (`bg-lightColor
        flex ${flexDirection ? flexDirection === 'row' ? 'flex-row' : 'flex-col' : ''}
        my-0 mx-auto max-w-[980px] ` + (className || ''))
        : ''}
      css={!className && css`
        background-color: ${theme
          ? 'var(--lightColor)'
          : 'var(--darkColor)'};
        display: flex;
        flex-direction: ${flexDirection};
        max-width: 980px;
        margin: 0 auto;
        ${customCss && customCss};
      `}
    >
      {children}
    </div>
  )
}
