import { DELETE_AREA, GET_AREAS, POST_AREA, UPDATE_AREA } from 'redux/actions/actions'
import { initialState } from './initialState'

export function areasReducer (state = initialState.areas, action) {
  const { type, payload } = action
  switch (type) {
    case GET_AREAS:
      return payload

    case DELETE_AREA:
      return state.filter(({ id }) => id !== payload)

    case POST_AREA:
      return [payload, ...state]
    case UPDATE_AREA: {
      const areas = state.map(function (item) { return item.id === payload.id ? payload : item })
      return areas
    }
    default:
      return state
  }
}
