/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { css, jsx } from '@emotion/react'
import Container from 'utilities/Container'
import expertplans from 'svg/logo.svg'
import hourglass from 'animations/hourglass'
import { useSelector } from 'react-redux'

export default function Preloader () {
  const theme = useSelector(state => state.theme)
  return (
    <div
      css={css`
            min-height: 100vh;
            background-color: ${theme
               ? 'var(--lightColor)'
               : 'var(--darkColor)'};
         `}
    >
      <Container
        flexDirection='row'
        customCss={css`
               min-height: 100vh;
               align-items: center;
               justify-content: center;
            `}
      >
        <img
          src={expertplans} alt=''
          css={css`animation: ${hourglass} 1.2s infinite;`}
        />
      </Container>
    </div>
  )
}
