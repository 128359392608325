import { GET_LEADS_CONSUMED } from 'redux/actions/actions'
import { initialState } from './initialState'

export function leadsConsumedReducer (state = initialState.leadsConsumed, action) {
  switch (action.type) {
    case GET_LEADS_CONSUMED:
      return action.payload
    default:
      return state
  }
}
