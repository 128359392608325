import { GET_POSTS } from 'redux/actions/actions'
import { initialState } from './initialState'

export function blogPostsReducer (state = initialState.blogPosts, action) {
  const { type, payload } = action
  switch (type) {
    case GET_POSTS:
      return payload

    default:
      return state
  }
}
