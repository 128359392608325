
import { CHANGE_SHOW_BANNER } from 'redux/actions/actions'
import { initialState } from './initialState'

export function showBannerReducer (state = initialState.showBanner, action) {
  const { type, payload } = action
  switch (type) {
    case CHANGE_SHOW_BANNER :
      return payload
    default:
      return state
  }
}
