
import { CHANGE_CONSULTANT_MODAL } from 'redux/actions/actions'
import { initialState } from './initialState'

export function modalsReducers (state = initialState.modals, action) {
  const { type, payload } = action
  switch (type) {
    case CHANGE_CONSULTANT_MODAL:
      return payload
    default:
      return state
  }
}
