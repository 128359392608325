
import { CHANGE_LANDING_PAGE } from 'redux/actions/actions'
import { initialState } from './initialState'

export function changeLandingPageReducer (state = initialState.changeLandingPage, action) {
  const { type, payload } = action
  switch (type) {
    case CHANGE_LANDING_PAGE:
      return payload
    default:
      return state
  }
}
