
import { GET_DESIGN_CONSULTANTS } from 'redux/actions/actions'
import { initialState } from './initialState'

export function designConsultantsReducer (state = initialState.designConsultants, action) {
  const { type, payload } = action
  switch (type) {
    case GET_DESIGN_CONSULTANTS:
      return payload
    default:
      return state
  }
}
