import { GET_LEADS_MONTH } from 'redux/actions/actions'
import { initialState } from './initialState'

export function leadsMonthReducer (state = initialState.leadsMonth, action) {
  switch (action.type) {
    case GET_LEADS_MONTH:
      return action.payload
    default:
      return state
  }
}
