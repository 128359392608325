import { GET_USER_PHOTOS, POST_USER_PHOTOS, DELETE_USER_PHOTOS } from 'redux/actions/actions'
import { initialState } from './initialState'

export function userPhotosReducer (state = initialState.userPhotos, action) {
  const { type, payload } = action
  switch (type) {
    case GET_USER_PHOTOS:
      return payload
    case POST_USER_PHOTOS:
      return payload
    case DELETE_USER_PHOTOS:
      return payload
    default:
      return state
  }
}
