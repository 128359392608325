
import { GET_SURVEYORS } from 'redux/actions/actions'
import { initialState } from './initialState'

export function surveyorsReducer (state = initialState.surveyors, action) {
  const { type, payload } = action
  switch (type) {
    case GET_SURVEYORS:
      return payload
    default:
      return state
  }
}
