import { GET_LEADS_LEFT } from 'redux/actions/actions'
import { initialState } from './initialState'

export function leadsLeftReducer (state = initialState.leadsLeft, action) {
  switch (action.type) {
    case GET_LEADS_LEFT:
      return action.payload
    default:
      return state
  }
}
