export const initialState = {
  user: false,
  areas: false,
  invoices: false,
  leads: false,
  leadsMonth: false,
  leadsConsumed: false,
  leadsLeft: false,
  messages: false,
  projectTypes: false,
  consultantTypes: false,
  steps: 1,
  modals: false,
  blogPosts: false,
  showBanner: false,
  changeLandingPage: false,
  userPhotos: false,
  consultantTypesCategory: 0,
  builders: false,
  surveyors: false,
  designConsultants: false
}
