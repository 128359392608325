import { GET_USER, RENEW_USER, UPDATE_USER } from 'redux/actions/actions'
import { initialState } from './initialState'

export function userReducer (state = initialState.user, action) {
  const { type, payload } = action
  switch (type) {
    case GET_USER:
      return payload
    case UPDATE_USER:
      return payload
    case RENEW_USER:
      return payload
    default:
      return state
  }
}
