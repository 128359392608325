
import { GET_PROJECT_TYPES } from 'redux/actions/actions'
import { initialState } from './initialState'

export function projectTypesReducer (state = initialState.projectTypes, action) {
  const { type, payload } = action
  switch (type) {
    case GET_PROJECT_TYPES:
      return payload
    default:
      return state
  }
}
