import { GET_LEADS, UPDATE_LEAD } from 'redux/actions/actions'
import { initialState } from './initialState'

export function leadsReducer (state = initialState.leads, action) {
  const { type, payload } = action
  switch (type) {
    case GET_LEADS:
      return payload
    case UPDATE_LEAD: {
      const leads = state.rows.map((item) => { return item.id === payload.id ? payload : item })
      const array = {
        count: state.count,
        rows: leads
      }
      return array
    }
    default:
      return state
  }
}
