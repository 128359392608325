import { combineReducers } from 'redux'
import themeReducer from './themeReducer'
import { areasReducer } from './areasReducer'
import { leadsReducer } from './leadsReducer'
import { invoicesReducer } from './invoicesReducer'
import { leadsMonthReducer } from './leadsMonthReducer'
import { leadsLeftReducer } from './leadsLeftReducer'
import { leadsConsumedReducer } from './leadsConsumedReducer'
import { userReducer } from './userReducer'
import { LOGOUT } from 'redux/actions/actions'
import { messagesReducer } from './messagesReducer'
import { projectTypesReducer } from './projectTypesReducer'
import { consultantTypesReducer } from './consultantTypesReducer'
import { consultantTypesCategoryReducer } from './consultantTypesCategoryReducer'
import { stepsReducer } from './stepsReducer'
import { modalsReducers } from './modalsReducer'
import { blogPostsReducer } from './blogPostsReducer'
import { showBannerReducer } from './showBannerReducer'
import { changeLandingPageReducer } from './changeLandingPageReducer'
import { userPhotosReducer } from './userPhotosReducer'
import { buildersReducer } from './buildersReducer'
import { surveyorsReducer } from './surveyorsReducer'
import { designConsultantsReducer } from './designConsultantsReducer'

const appReducer = combineReducers({
  theme: themeReducer,
  areas: areasReducer,
  leads: leadsReducer,
  invoices: invoicesReducer,
  leadsMonth: leadsMonthReducer,
  leadsLeft: leadsLeftReducer,
  leadsConsumed: leadsConsumedReducer,
  user: userReducer,
  messages: messagesReducer,
  projectTypes: projectTypesReducer,
  consultantTypes: consultantTypesReducer,
  consultantTypesCategory: consultantTypesCategoryReducer,
  steps: stepsReducer,
  modals: modalsReducers,
  blogPosts: blogPostsReducer,
  showBanner: showBannerReducer,
  changeLandingPage: changeLandingPageReducer,
  userPhotos: userPhotosReducer,
  builders: buildersReducer,
  surveyors: surveyorsReducer,
  designConsultants: designConsultantsReducer
})

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}
