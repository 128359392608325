
export const CHANGE_THEME = 'CHANGE_THEME'

export const GET_USER = 'GET_USER'
export const RENEW_USER = 'RENEW_USER'
export const UPDATE_USER = 'UPDATE_USER'

export const GET_LEADS = 'GET_LEADS'
export const GET_LEADS_MONTH = 'GET_LEADS_MONTH'
export const GET_LEADS_LEFT = 'GET_LEADS_LEFT'
export const GET_LEADS_CONSUMED = 'GET_LEADS_CONSUMED'
export const UPDATE_LEAD = 'UPDATE_LEAD'

export const GET_AREAS = 'GET_AREAS'
export const UPDATE_AREA = 'UPDATE_AREA'
export const DELETE_AREA = 'DELETE_AREA'
export const POST_AREA = 'POST_AREA'

export const GET_INVOICES = 'GET_INVOICES'

export const LOGOUT = 'LOGOUT'

export const GET_CONSULTANT_TYPES = 'GET_CONSULTANT_TYPES'
export const GET_PROJECT_TYPES = 'GET_PROJECT_TYPES'

export const GET_CONSULTANT_CATEGORY = 'GET_CONSULTANT_CATEGORY'
export const SET_CONSULTANT_CATEGORY = 'SET_CONSULTANT_CATEGORY'

export const GET_BUILDERS = 'GET_BUILDERS'
export const GET_SURVEYORS = 'GET_SURVEYORS'
export const GET_DESIGN_CONSULTANTS = 'GET_DESIGN_CONSULTANTS'

export const SET_MESSAGES = 'SET_MESSAGES'

export const CHANGE_STEP = 'CHANGE_STEP'

export const CHANGE_CONSULTANT_MODAL = 'CHANGE_CONSULTANT_MODAL'

export const GET_POSTS = 'GET_POSTS'

export const CHANGE_SHOW_BANNER = 'CHANGE_SHOW_BANNER'

export const CHANGE_LANDING_PAGE = 'CHANGE_LANDING_PAGE'

export const GET_USER_PHOTOS = 'GET_USER_PHOTOS'
export const POST_USER_PHOTOS = 'POST_USER_PHOTOS'
export const DELETE_USER_PHOTOS = 'DELETE_USER_PHOTOS'
