
import { SET_MESSAGES } from 'redux/actions/actions'
import { initialState } from './initialState'

export function messagesReducer (state = initialState.messages, action) {
  const { type, payload } = action
  switch (type) {
    case SET_MESSAGES:
      return payload
    default:
      return state
  }
}
