
import { GET_BUILDERS } from 'redux/actions/actions'
import { initialState } from './initialState'

export function buildersReducer (state = initialState.builders, action) {
  const { type, payload } = action
  switch (type) {
    case GET_BUILDERS:
      return payload
    default:
      return state
  }
}
