
import { GET_CONSULTANT_CATEGORY, SET_CONSULTANT_CATEGORY } from 'redux/actions/actions'
import { initialState } from './initialState'

export function consultantTypesCategoryReducer (state = initialState.consultantTypesCategory, action) {
  const { type, payload } = action
  switch (type) {
    case GET_CONSULTANT_CATEGORY:
      return payload
    case SET_CONSULTANT_CATEGORY:
      return payload
    default:
      return state
  }
}
