
import { CHANGE_STEP } from 'redux/actions/actions'
import { initialState } from './initialState'

export function stepsReducer (state = initialState.steps, action) {
  const { type, payload } = action
  switch (type) {
    case CHANGE_STEP:
      return payload
    default:
      return state
  }
}
