
import { GET_CONSULTANT_TYPES } from 'redux/actions/actions'
import { initialState } from './initialState'

export function consultantTypesReducer (state = initialState.consultantTypes, action) {
  const { type, payload } = action
  switch (type) {
    case GET_CONSULTANT_TYPES:
      return payload
    default:
      return state
  }
}
